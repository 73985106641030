import { useState } from "react";

import useStorage from "./useStorage";

const initialState = {
    darkMode: window.matchMedia('(prefers-color-scheme: dark)').matches,
    helpOnLoad: true,
};

export default function useSettings() {
    const storage = useStorage('emoji-settings');

    const [settings, setSettings] = useState(() => {
        const data = storage.get();

        if (data) {
            return data;
        }

        storage.set(initialState);
        return initialState;
    });

    function handleSetSettings(data) {
        setSettings(data);
        storage.set(data);
    }

    return [settings, handleSetSettings];
}
