import { useEffect } from "react";
import { TbChartBar, TbHelp, TbSettings } from "react-icons/tb";

import { useModal } from "src/contexts/Modal";
import Button from "src/elements/Button";
import useSettings from "src/hooks/useSettings";

import HelpModal from "../HelpModal";
import SettingsModal from "../SettingsModal";
import StatsModal from "../StatsModal";

import styles from './Nav.module.css';

export function Nav() {
    const setModal = useModal();
    const [settings] = useSettings();

    useEffect(() => {
        if (!settings.helpOnLoad) {
            return;
        }

        setModal(<HelpModal />);

        return () => {
            setModal(undefined);
        };
    }, []);

    function handleActivateModal(component) {
        return () => setModal(component);
    }

    return (
        <header className={styles.header}>
            <div></div>
            <h1>PopMoji</h1>
            <div>
                <Button title="Help" size="small" onClick={handleActivateModal(<HelpModal />)}>
                    <TbHelp />
                </Button>

                <Button title="Stats" size="small" onClick={handleActivateModal(<StatsModal />)}>
                    <TbChartBar />
                </Button>

                <Button title="Settings" size="small" onClick={handleActivateModal(<SettingsModal />)}>
                    <TbSettings />
                </Button>
            </div>
        </header>
    );
}
