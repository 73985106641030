import gameList from '../i18n/en_US/gameList.json';
import app from '../i18n/en_US/app.json';
// import tv from '../i18n/en_US/tv.json';

export default function useI18n() {
    function getValue(string, obj) {
        var properties = string.split('.');
        return properties.reduce((acc, property) => acc[property], obj);
    }

    function formatValue(str, obj) {
        return Object.keys(obj).reduce((acc, key) => {
            return acc.replace(`{${key}}`, obj[key]);
        }, str);
    }

    return function (translate, replace = {}) {
        const value = getValue(translate, { ...gameList, ...app });

        if (!value) {
            return;
        }

        if (typeof value !== 'string') {
            return value;
        }

        return formatValue(value, replace);
    };
}
