import { useModal } from "src/contexts/Modal";
import Button from "src/elements/Button";
import useI18n from "src/hooks/useI18n";
import useSettings from "src/hooks/useSettings";

import modalStyles from "src/contexts/Modal/Modal.module.css";
import styles from "./HelpModal.module.css";

export function HelpModal() {
    const t = useI18n();
    const setModal = useModal();
    const [settings, setSettings] = useSettings();

    function handlePlayGame() {
        setModal(undefined);
        setSettings({ ...settings, helpOnLoad: false });
    }

    function handleClose() {
        setModal(undefined);
    }

    return (
        <>
            <div className={[modalStyles.body, styles.body].join(" ").trim()}>
                <h2>{t('help.title')}</h2>
                <h5>{t('help.subTitle')}</h5>

                <ul>
                    <li>{t('help.rule1')}</li>
                    <li>{t('help.rule2')}</li>
                </ul>

                <h3>{t('help.exampleTitle')}</h3>
                <p>{t('help.example')}</p>
                <p>{t('help.exampleAnswer')}</p>
            </div>

            <div className={modalStyles.actions}>
                {
                    settings.helpOnLoad
                        ? <Button onClick={handlePlayGame}>{t('help.play')}</Button>
                        : <Button onClick={handleClose}>{t('close')}</Button>
                }
            </div>
        </>
    );
}
