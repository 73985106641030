import styles from './Button.module.css';

export function Button({ size, onClick, children, ...props }) {

    return (
        <button
            {...props}
            className={[styles.container, styles[size]].join(" ").trim()}
            onClick={onClick}
        >
            {children}
        </button>
    );
}
