export default function useStorage(key) {
    function get() {
        const data = localStorage.getItem(key);

        if (!data) {
            return;
        }

        return JSON.parse(data);
    }

    function set(data) {
        localStorage.setItem(key, JSON.stringify(data));
    }

    function remove() {
        localStorage.removeItem(key);
    }

    return { get, set, remove };
}
