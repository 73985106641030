import { useModal } from "src/contexts/Modal";
import modalStyles from "src/contexts/Modal/Modal.module.css";
import Button from "src/elements/Button";
import Toggle from "src/elements/Toggle";
import useI18n from "src/hooks/useI18n";
import useSettings from "src/hooks/useSettings";

import styles from "./SettingsModal.module.css";

export function SettingsModal() {
    const t = useI18n();
    const setModal = useModal();

    const [settings, setSettings] = useSettings();

    function handleDarkModeToggle() {
        setSettings({ ...settings, darkMode: !settings.darkMode });

        if (!settings.darkMode) {
            document.body.classList.add('dark');
        } else {
            document.body.classList.remove('dark');
        }
    }

    function handleClose() {
        setModal(undefined);
    }

    return (
        <>
            <div className={[modalStyles.body, styles.body].join(" ").trim()}>
                <h2>{t('settings.title')}</h2>
                <div>
                    <label>Dark Mode</label>
                    <Toggle value={settings.darkMode} onChange={handleDarkModeToggle} />
                </div>
            </div>

            <div className={modalStyles.actions}>
                <Button onClick={handleClose}>Close</Button>
            </div>
        </>
    );
}
