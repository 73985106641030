import { motion } from 'framer-motion';

import styles from './Input.module.css';

export function Input({ value, onChange, onKeyUp, ...props }) {
    function handleChange(e) {
        onChange(e.target.value);
    }

    return (
        <motion.input
            {...props}
            className={styles.input}
            value={value}
            onChange={handleChange}
            onKeyUp={onKeyUp}
        />
    );
}
