import { TbShare } from "react-icons/tb";

import { useModal } from "src/contexts/Modal";
import modalStyles from "src/contexts/Modal/Modal.module.css";
import { useToast } from "src/contexts/Toast";
import Button from "src/elements/Button";
import useGame from "src/hooks/useGame";
import useI18n from "src/hooks/useI18n";
import useStats from "src/hooks/useStats";
import useTimer from "src/hooks/useTimer";
import maxRounds from "src/utils/maxRounds";
import getTimeUntilMidnight from "src/utils/getTimeUntilMidnight";

import styles from './StatsModal.module.css';

const timeIntl = Intl.NumberFormat('en', {
    minimumIntegerDigits: 2,
});

const percIntl = Intl.NumberFormat('en', {
    maximumFractionDigits: 0,
});

export function StatsModal() {
    const t = useI18n();
    const setModal = useModal();
    const setToast = useToast();

    const [game] = useGame();
    const [stats] = useStats();
    const { time, stopTimer } = useTimer();

    function handleClose() {
        setModal(undefined);
    }

    function handleShareClick() {
        setToast(t('stats.share.copiedToClipboard'));
    }

    const nextGameInterval = getTimeUntilMidnight();
    const hoursInSeconds = nextGameInterval.hours * 60 * 60;
    const minutesInSeconds = nextGameInterval.minutes * 60;
    const timerLimit = hoursInSeconds + minutesInSeconds + nextGameInterval.seconds;

    if (time > timerLimit) {
        stopTimer();
    }

    const successGuesses = Object.entries(stats.guesses)
        .filter(([key, value]) => key !== 'fail');

    const succeeded = successGuesses.reduce((pre, [key, value]) => value + pre, 0);
    const failed = stats.guesses.fail;
    const gamesPlayed = succeeded + failed;

    return (
        <>
            <div className={modalStyles.body}>
                <div className={styles.stats}>
                    <h2>{t('stats.title')}</h2>
                    <div>
                        <h3>{gamesPlayed}</h3>
                        <p>{t('stats.played')}</p>
                    </div>
                    <div>
                        <h3>{!gamesPlayed ? 0 : percIntl.format(succeeded * 100 / gamesPlayed)}%</h3>
                        <p>{t('stats.winPercentage')}</p>
                    </div>
                    <div>
                        <h3>{stats.streak}</h3>
                        <p>{t('stats.currentStreak')}</p>
                    </div>
                    <div>
                        <h3>{stats.maxStreak}</h3>
                        <p>{t('stats.maxStreak')}</p>
                    </div>
                </div>

                <div className={styles.roundDist}>
                    <h2>{t('stats.guesses')}</h2>
                    {
                        successGuesses.map(([key, value]) => {
                            return (
                                <div
                                    key={key}
                                    style={{ width: `${!gamesPlayed ? 0 : value * 100 / gamesPlayed}%` }}
                                >
                                    <p>{key}</p>

                                    <div className={
                                        game.status !== 'pending' && (Number(key) === game.boardState.indexOf(null) || (game.status === "success" && Number(key) === 5 && game.boardState.indexOf(null) === -1))
                                            ? styles.current
                                            : ''
                                    }>
                                        <p>{value}</p>
                                    </div>
                                </div>
                            );
                        })
                    }
                </div>
            </div>

            <div className={[modalStyles.actions, styles.actions].join(" ").trim()}>
                {
                    game.status !== 'pending' && time <= timerLimit && (
                        <p>{t('stats.nextGameIn')} {timeIntl.format(nextGameInterval.hours)}:{timeIntl.format(nextGameInterval.minutes)}:{timeIntl.format(nextGameInterval.seconds)}</p>
                    )
                }

                {
                    game.status !== 'pending' && time > timerLimit && (
                        <p>{t('stats.refreshPage')}</p>
                    )
                }

                <Button onClick={handleClose}>{t('close')}</Button>

                {
                    game.status !== 'pending' && (
                        <Button onClick={handleShareClick}>{t('stats.share.label')} <TbShare /></Button>
                    )
                }
            </div>
        </>
    );
}
