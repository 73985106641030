import { AnimatePresence, motion } from "framer-motion";
import { useMemo } from "react";
import { TbCircleCheck, TbCircleX } from "react-icons/tb";

import useGame from "src/hooks/useGame";
import useI18n from "src/hooks/useI18n";

import styles from './GuessPanel.module.css';

export function GuessPanel({ answer }) {
    const t = useI18n();
    const [game] = useGame();

    const gameList = t('gameList');

    const guesses = useMemo(() =>
        game.boardState
            .map(bs => bs ? gameList.find(m => m.answers.includes(bs)) : bs),
        []);

    const selectedIndex = guesses.filter(bs => bs).length - 1;

    return (
        <div className={styles.container}>
            <AnimatePresence>
                {
                    guesses.map((bs, idx) => (
                        <div key={idx}>
                            {
                                bs && (
                                    <>
                                        <motion.div
                                            style={{ backgroundImage: `url(${bs.image})` }}
                                            initial={selectedIndex === idx ? { height: "0%" } : undefined}
                                            animate={selectedIndex === idx ? { height: "100%" } : undefined}
                                        />

                                        <motion.div
                                            initial={selectedIndex === idx ? { scale: 0 } : undefined}
                                            animate={selectedIndex === idx ? { rotate: 360, scale: 1, translateX: "-50%", translateY: "-50%" } : undefined}
                                            transition={selectedIndex === idx ? { type: 'spring', stiffness: 260, damping: 30 } : undefined}
                                        >
                                            {
                                                selectedIndex === idx && game.status === "success"
                                                    ? <TbCircleCheck className={styles.success} />
                                                    : <TbCircleX className={styles.danger} />
                                            }
                                        </motion.div>
                                    </>
                                )
                            }
                        </div>
                    ))
                }
            </AnimatePresence>

            {
                !guesses.length && <span>{t('game.guessBelow')}</span>
            }
        </div>
    );
}
