import { useState } from "react";

import getDayOfYear from "src/utils/getDayOfYear";

import useStorage from "./useStorage";

const initialState = {
    status: 'pending',
    boardState: [...new Array(5).fill(null)],
    hintsUsed: 0,
    lastPlayed: null,
};

export default function useGame() {
    const storage = useStorage(`emoji-game`);

    const [game, setGame] = useState(() => {
        const data = storage.get();

        if (!data) {
            storage.set(initialState);
            return initialState;
        }

        if (!data.lastPlayed) {
            return data;
        }

        const lastPlayedDate = getDayOfYear(new Date(data.lastPlayed));
        const todaysDate = getDayOfYear();

        if (lastPlayedDate < todaysDate) {
            storage.set(initialState);
            return initialState;
        }

        return data;
    });

    function handleSetGame(data) {
        setGame(data);
        storage.set(data);
    }

    return [game, handleSetGame];
}
