import { createContext, useContext, useEffect, useState } from "react";
import { motion, useAnimationControls } from "framer-motion";

import styles from './Toast.module.css';

const ToastContext = createContext();

export function ToastContextProvider({ children }) {
    const control = useAnimationControls();
    const [message, setMessage] = useState("");

    useEffect(() => {
        if (!message) {
            return;
        }

        const animate = async () => {
            await control.start({
                scale: [0, 1],
                transition: { type: 'spring', ease: "easeIn", duration: .5 },
            });

            handleAnimationEnd();
        };

        animate();

        return () => {
            control.stop();
        };
    }, [message]);

    async function handleAnimationEnd() {
        control.stop();

        setTimeout(async () => {
            await control.start({
                scale: [1, 0],
                transition: { type: 'spring', ease: "easeIn", duration: .3 },
            });

            control.stop();
            setMessage('');
        }, 750);
    }

    return (
        <ToastContext.Provider value={setMessage}>
            {
                message
                    ? (
                        <div className={styles.container}>
                            <motion.div animate={control}>
                                {message}
                            </motion.div>
                        </div>
                    ) : null
            }

            {children}
        </ToastContext.Provider>
    );
}

export function useToast() {
    const context = useContext(ToastContext);

    if (!context) {
        throw new Error('useToast must be used within ToastContextProvider');
    }

    return context;
}
