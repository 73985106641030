import { useState } from "react";

import useStorage from "./useStorage";

const initialState = {
    maxStreak: 0,
    streak: 0,
    guesses: {
        1: 0,
        2: 0,
        3: 0,
        4: 0,
        5: 0,
        fail: 0,
    },
};

export default function useStats() {
    const storage = useStorage('emoji-stats');

    const [stats, setStats] = useState(() => {
        const data = storage.get();

        if (data) {
            return data;
        }

        storage.set(initialState);
        return initialState;
    });

    function handleSetStats(data) {
        storage.set(data);
        setStats(data);
    }

    return [stats, handleSetStats];
}
