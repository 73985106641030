import { useAnimationControls } from "framer-motion";
import { useEffect, useState } from "react";

import GuessPanel from "src/components/GuessPanel";
import { useModal } from "src/contexts/Modal";
import { useToast } from "src/contexts/Toast";
import Button from "src/elements/Button";
import Input from "src/elements/Input";
import useGame from "src/hooks/useGame";
import useI18n from "src/hooks/useI18n";
import useStats from "src/hooks/useStats";
import getDayOfYear from "src/utils/getDayOfYear";
import isYesterday from "src/utils/isYesterday";

import HintsModal from "../HintsModal";
import StatsModal from "../StatsModal";

import styles from './Game.module.css';

export function Game() {
    const control = useAnimationControls();

    const t = useI18n();
    const setModal = useModal();
    const setToast = useToast();
    const [game, setGame] = useGame();
    const [stats, setStats] = useStats();

    const [guess, setGuess] = useState('');
    const [error, setError] = useState('');

    const gameList = t('gameList');
    const roundIndex = (getDayOfYear() - 1) % gameList.length;
    const round = gameList[roundIndex];

    useEffect(() => {
        if (!error) {
            return;
        }

        const animate = async () => {
            setToast(error);

            await control.start({
                x: [20, 0],
                transition: { type: 'spring', bounce: 0.9, ease: "easeIn" },
            });

            setGuess('');
            setError('');

            control.stop();
        };

        animate();

        return () => {
            control.stop();
        };
    }, [error]);

    function handleHintModal() {
        setModal(<HintsModal hints={round.hints} />);
    }

    function handleOnKeyUp(e) {
        if (e.key.toLowerCase() !== "enter") {
            return;
        }
        handleSubmit();
    }

    function handleSubmit() {
        if (game.status === 'failed' || game.status === 'success') {
            setError(t('game.errors.finished'));
            return;
        }

        if (!guess) {
            setError(t('game.errors.empty'));
            return;
        }

        const guessObject = gameList.reduce((pre, cur) => {
            return cur.answers.includes(guess.toLowerCase())
                ? cur
                : pre;
        }, undefined);

        setGuess('');

        if (!guessObject) {
            setError(t('game.errors.notInList'));
            return;
        }

        if (game.boardState.includes(guessObject.answers[0])) {
            setError(t('game.errors.conflict'));
            return;
        }

        const boardStateIndex = game.boardState.indexOf(null);
        const lastRound = boardStateIndex + 1 === 5;
        const guessedCorrectly = guessObject.answers.join(',') === round.answers.join(',');

        let status = 'pending';

        if (guessedCorrectly) {
            status = 'success';
            const encouragements = t('game.affirmations');
            const randomIndex = Math.floor(Math.random() * encouragements.length);
            setToast(encouragements[randomIndex]);
        }

        if (status !== 'success' && lastRound) {
            status = 'failed';
            setToast(round.answers[0]);
        }

        setGame({
            ...game,
            boardState: [
                ...game.boardState.slice(0, boardStateIndex),
                guessObject.answers[0],
                ...game.boardState.slice(boardStateIndex + 1),
            ],
            lastPlayed: game.lastPlayed ?? new Date(),
            status,
        });

        if (status === 'pending') {
            return;
        }

        const onStreak = game.lastPlayed ? isYesterday(game.lastPlayed) : false;
        const streak = onStreak ? stats.streak + 1 : 1;
        const maxStreak = streak > stats.maxStreak ? streak : stats.maxStreak;
        const guessIndex = status === 'success' ? boardStateIndex + 1 : 'fail';

        setStats({
            ...stats,
            streak,
            maxStreak,
            guesses: {
                ...stats.guesses,
                [guessIndex]: stats.guesses[guessIndex] + 1,
            },
        });

        setTimeout(() => {
            setModal(<StatsModal />);
        }, 1250);
    }

    return (
        <main className={styles.main}>
            <h1>{round.emoji}</h1>

            <Button onClick={() => setGame({ ...game, boardState: [...new Array(5).fill(null)], status: "pending", lastPlayed: null })}>
                Clear State
            </Button>

            <GuessPanel
                key={game.boardState.filter(bs => bs).length}
                round={round}
            />

            <div className={styles.actions}>
                <Input
                    value={guess}
                    placeholder="Start typing..."
                    onChange={setGuess}
                    onKeyUp={handleOnKeyUp}
                    animate={control}
                />

                <Button onClick={handleHintModal}>{t('game.hints')}</Button>
                <Button onClick={handleSubmit}>{t('game.submit')}</Button>
            </div>
        </main>
    );
}
