import { useEffect, useState } from "react";

import { useModal } from "src/contexts/Modal";
import Button from "src/elements/Button";
import useGame from "src/hooks/useGame";

import modalStyles from "src/contexts/Modal/Modal.module.css";
import useI18n from "src/hooks/useI18n";

export function HintsModal({ hints }) {
    const t = useI18n();
    const setModal = useModal();
    const [game, setGame] = useGame();

    const [hintsUsed, setHintsUsed] = useState(game.hintsUsed);

    useEffect(() => {
        if (hintsUsed == game.hintsUsed) {
            return;
        }

        setGame({ ...game, hintsUsed });
    }, [hintsUsed]);

    function handleClose() {
        setModal(undefined);
    }

    function handleConsumed() {
        setHintsUsed(hintsUsed + 1);
    }

    return (
        <>
            <div className={modalStyles.body}>
                <h1>{t('hints.title')}</h1>

                <div>
                    <h3>#1</h3>
                    <p>{hintsUsed > 0 ? hints[0] : "???"}</p>
                </div>

                <div>
                    <h3>#2</h3>
                    <p>{hintsUsed > 1 ? hints[1] : "???"}</p>
                </div>

                <div>
                    <h3>#3</h3>
                    <p>{hintsUsed > 2 ? hints[2] : "???"}</p>
                </div>
            </div>

            <div className={modalStyles.actions}>
                <Button onClick={handleClose}>{t('close')}</Button>
                {
                    hintsUsed <= 2 ? (
                        <Button onClick={handleConsumed}>{t('hints.unlock')} #{hintsUsed + 1}</Button>
                    ) : null
                }
            </div>
        </>
    );
}
