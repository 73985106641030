import ModalContextProvider from "./contexts/Modal";
import ToastContextProvider from "./contexts/Toast";
import useSettings from "./hooks/useSettings";

import Game from "./layouts/Game";
import Nav from "./layouts/Nav";

export default function App() {
    const [settings] = useSettings();

    if (settings.darkMode) {
        document.body.classList.add('dark');
    }

    return (
        <ToastContextProvider>
            <ModalContextProvider>
                <Nav />
                <Game />
            </ModalContextProvider>
        </ToastContextProvider>
    );
}
